import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { observer } from 'mobx-react-lite';
import { Store } from 'model/store/Store';

import { Page } from '../../../components/Page/Page';
import { useUpdateDomainMutation } from '../../../generated/graphql';
import { workspaceRegex } from '../../../shared/regex';

interface IForm {
  name: string
}

export const Rename = observer(() => {

  const { domain } = useInstance(Store)
  const {t} = useTranslation()
  const [updateDomain, {loading}] = useUpdateDomainMutation()
  const navigate = useNavigate()

  const onFinish = async ({name} : IForm) => {
    await updateDomain({
      variables: {
        id: domain.selectedDomain!,
        data: { name: name, }
      },
      onCompleted: () => {
        navigate(-1)
      }
    })
  }

  return (
    <Page name={t('Rename Workspace')!} title={domain.currentDomain().name}>
      <Page.Content>

        <Form size={'large'} layout={'vertical'} initialValues={{name: domain.currentDomain().name}} onFinish={onFinish} disabled={loading}>
          <Form.Item name={'name'} label={t('New name')}
                     validateFirst
                     rules={[
                       {required: true, message: `${t('Required')}`},
                       { min: 4, message: `${t('Min 4 symbols')}` },
                       { pattern: workspaceRegex, message: t('Only latin letters and digits')! },
                     ]}
          >
            <Input/>
          </Form.Item>
          <Form.Item>
            <Button htmlType={'submit'} type={'primary'} loading={loading}>{t('Save')}</Button>
          </Form.Item>
        </Form>

      </Page.Content>
    </Page>
  )

})