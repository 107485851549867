import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { PlusOutlined } from '@ant-design/icons';
import { Button, ColorPicker, Divider, Form, Input, Modal, Select } from 'antd';
import { presets, random } from 'config/colors';
import { useCompaniesQuery, useCreateCompanyMutation } from 'generated/graphql';
import _, { isString } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Store } from 'model/store/Store';

interface IForm {
  name: string
  color: string
}

export const SelectClient = observer((props:any) => {

  const { onCreate, ...rest } = props

  const { t } = useTranslation();
  const store = useInstance(Store);

  const query = useCompaniesQuery({
    variables: {
      filters: {
        enabled: {eq: true},
        domain: {id: {eq: store.domain.selectedDomain}},
      },
      sort: ['name:ASC'],
      pagination: { limit: 100 },
    }
  })
  const [create] = useCreateCompanyMutation()

  const options = _.map(query.data?.companies?.data, item => ({
    value: item.id,
    label: item.attributes?.name!
  }))

  const [open, setOpen] = useState(false);
  const [form] = Form.useForm()

  const onAdd = async (values:IForm) => {
    await create({
      variables: {
        data: {
          name: values.name,
          // @ts-ignore
          color: isString(values.color) ? values.color : values.color.toHexString(),
          domain: store.domain.selectedDomain,
        }
      },
      onCompleted: (data) => {
        if (onCreate) {onCreate(data.createCompany?.data?.id!)}
        setOpen(false)
        form.resetFields()
        query.refetch()
      }
    })
  }

  const onClose = () => {
    setOpen(false)
    form.resetFields()
  }

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Select {...rest}
              options={options}
              allowClear={true}
              popupMatchSelectWidth={false}
              placeholder={t('No Client')}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <Button type="text" block icon={<PlusOutlined />} onClick={() => setOpen(true)} />
                </>
              )}
      />
      <Modal open={open} onCancel={onClose} footer={null} closable={false} width={360}>
        <Form layout={'inline'} onFinish={onAdd} form={form}>
          <Form.Item name={'name'}
                     rules={[
                       {
                         required: true,
                         message: t('Please enter the data') || '',
                       },
                     ]}
                     initialValue={''}
          >
            <Input bordered={false} placeholder={t('New Client')!} style={{width: 148}}/>
          </Form.Item>
          <Form.Item name={'color'} initialValue={random()}
                     rules={[
                       {
                         required: true,
                         message: t('Please enter the data') || '',
                       },
                     ]}
          >
            <ColorPicker presets={presets} panelRender={(_, {components: {Presets}}) => <Presets />}/>
          </Form.Item>
          <Form.Item>
            <Button htmlType={'submit'} icon={<PlusOutlined />}>{t('Add')}</Button>
          </Form.Item>
        </Form>

      </Modal>
    </>
  );
})
