import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  JSON: any;
  Upload: any;
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  contains?: InputMaybe<Scalars['Boolean']>;
  containsi?: InputMaybe<Scalars['Boolean']>;
  endsWith?: InputMaybe<Scalars['Boolean']>;
  eq?: InputMaybe<Scalars['Boolean']>;
  eqi?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Boolean']>;
  gte?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  lt?: InputMaybe<Scalars['Boolean']>;
  lte?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
  nei?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<BooleanFilterInput>;
  notContains?: InputMaybe<Scalars['Boolean']>;
  notContainsi?: InputMaybe<Scalars['Boolean']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  startsWith?: InputMaybe<Scalars['Boolean']>;
};

export type Company = {
  __typename?: 'Company';
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  domain?: Maybe<DomainEntityResponse>;
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CompanyEntity = {
  __typename?: 'CompanyEntity';
  attributes?: Maybe<Company>;
  id?: Maybe<Scalars['ID']>;
};

export type CompanyEntityResponse = {
  __typename?: 'CompanyEntityResponse';
  data?: Maybe<CompanyEntity>;
};

export type CompanyEntityResponseCollection = {
  __typename?: 'CompanyEntityResponseCollection';
  data: Array<CompanyEntity>;
  meta: ResponseCollectionMeta;
};

export type CompanyFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CompanyFiltersInput>>>;
  color?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  domain?: InputMaybe<DomainFiltersInput>;
  enabled?: InputMaybe<BooleanFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CompanyFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CompanyFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CompanyInput = {
  color?: InputMaybe<Scalars['String']>;
  domain?: InputMaybe<Scalars['ID']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ComponentDomainUserInDomain = {
  __typename?: 'ComponentDomainUserInDomain';
  active?: Maybe<Scalars['Boolean']>;
  admin: Scalars['Boolean'];
  confirmed: Scalars['Boolean'];
  id: Scalars['ID'];
  user?: Maybe<UsersPermissionsUserEntityResponse>;
};

export type ComponentFinanceLine = {
  __typename?: 'ComponentFinanceLine';
  count?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  sum: Scalars['Float'];
  unit?: Maybe<Enum_Componentfinanceline_Unit>;
};

export type ComponentFinanceProps = {
  __typename?: 'ComponentFinanceProps';
  addres?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<Scalars['String']>;
  bankBik?: Maybe<Scalars['String']>;
  bankCorr?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  id: Scalars['ID'];
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  signName?: Maybe<Scalars['String']>;
};

export type ComponentPresentationHeadline = {
  __typename?: 'ComponentPresentationHeadline';
  id: Scalars['ID'];
  text: Scalars['String'];
};

export type ComponentPresentationMedia = {
  __typename?: 'ComponentPresentationMedia';
  id: Scalars['ID'];
  media: UploadFileEntityResponse;
};

export type ComponentPresentationText = {
  __typename?: 'ComponentPresentationText';
  id: Scalars['ID'];
  text: Scalars['String'];
};

export type ComponentTaskMessage = {
  __typename?: 'ComponentTaskMessage';
  files?: Maybe<UploadFileRelationResponseCollection>;
  id: Scalars['ID'];
  message: Scalars['String'];
};


export type ComponentTaskMessageFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentReleasesRelease = {
  __typename?: 'ContentReleasesRelease';
  actions?: Maybe<ContentReleasesReleaseActionRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  releasedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ContentReleasesReleaseActionsArgs = {
  filters?: InputMaybe<ContentReleasesReleaseActionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentReleasesReleaseAction = {
  __typename?: 'ContentReleasesReleaseAction';
  contentType: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  entry?: Maybe<GenericMorph>;
  release?: Maybe<ContentReleasesReleaseEntityResponse>;
  type: Enum_Contentreleasesreleaseaction_Type;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ContentReleasesReleaseActionEntity = {
  __typename?: 'ContentReleasesReleaseActionEntity';
  attributes?: Maybe<ContentReleasesReleaseAction>;
  id?: Maybe<Scalars['ID']>;
};

export type ContentReleasesReleaseActionEntityResponse = {
  __typename?: 'ContentReleasesReleaseActionEntityResponse';
  data?: Maybe<ContentReleasesReleaseActionEntity>;
};

export type ContentReleasesReleaseActionEntityResponseCollection = {
  __typename?: 'ContentReleasesReleaseActionEntityResponseCollection';
  data: Array<ContentReleasesReleaseActionEntity>;
  meta: ResponseCollectionMeta;
};

export type ContentReleasesReleaseActionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ContentReleasesReleaseActionFiltersInput>>>;
  contentType?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<ContentReleasesReleaseActionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ContentReleasesReleaseActionFiltersInput>>>;
  release?: InputMaybe<ContentReleasesReleaseFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ContentReleasesReleaseActionInput = {
  contentType?: InputMaybe<Scalars['String']>;
  release?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<Enum_Contentreleasesreleaseaction_Type>;
};

export type ContentReleasesReleaseActionRelationResponseCollection = {
  __typename?: 'ContentReleasesReleaseActionRelationResponseCollection';
  data: Array<ContentReleasesReleaseActionEntity>;
};

export type ContentReleasesReleaseEntity = {
  __typename?: 'ContentReleasesReleaseEntity';
  attributes?: Maybe<ContentReleasesRelease>;
  id?: Maybe<Scalars['ID']>;
};

export type ContentReleasesReleaseEntityResponse = {
  __typename?: 'ContentReleasesReleaseEntityResponse';
  data?: Maybe<ContentReleasesReleaseEntity>;
};

export type ContentReleasesReleaseEntityResponseCollection = {
  __typename?: 'ContentReleasesReleaseEntityResponseCollection';
  data: Array<ContentReleasesReleaseEntity>;
  meta: ResponseCollectionMeta;
};

export type ContentReleasesReleaseFiltersInput = {
  actions?: InputMaybe<ContentReleasesReleaseActionFiltersInput>;
  and?: InputMaybe<Array<InputMaybe<ContentReleasesReleaseFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ContentReleasesReleaseFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ContentReleasesReleaseFiltersInput>>>;
  releasedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ContentReleasesReleaseInput = {
  actions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  releasedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DateFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  contains?: InputMaybe<Scalars['Date']>;
  containsi?: InputMaybe<Scalars['Date']>;
  endsWith?: InputMaybe<Scalars['Date']>;
  eq?: InputMaybe<Scalars['Date']>;
  eqi?: InputMaybe<Scalars['Date']>;
  gt?: InputMaybe<Scalars['Date']>;
  gte?: InputMaybe<Scalars['Date']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  lt?: InputMaybe<Scalars['Date']>;
  lte?: InputMaybe<Scalars['Date']>;
  ne?: InputMaybe<Scalars['Date']>;
  nei?: InputMaybe<Scalars['Date']>;
  not?: InputMaybe<DateFilterInput>;
  notContains?: InputMaybe<Scalars['Date']>;
  notContainsi?: InputMaybe<Scalars['Date']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  startsWith?: InputMaybe<Scalars['Date']>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  contains?: InputMaybe<Scalars['DateTime']>;
  containsi?: InputMaybe<Scalars['DateTime']>;
  endsWith?: InputMaybe<Scalars['DateTime']>;
  eq?: InputMaybe<Scalars['DateTime']>;
  eqi?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  ne?: InputMaybe<Scalars['DateTime']>;
  nei?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<DateTimeFilterInput>;
  notContains?: InputMaybe<Scalars['DateTime']>;
  notContainsi?: InputMaybe<Scalars['DateTime']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  startsWith?: InputMaybe<Scalars['DateTime']>;
};

export type Domain = {
  __typename?: 'Domain';
  activeTill?: Maybe<Scalars['Date']>;
  activeUsers: Scalars['Int'];
  blocked: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  demoUsed: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  funcConfig?: Maybe<Scalars['JSON']>;
  invites?: Maybe<InviteRelationResponseCollection>;
  name: Scalars['String'];
  roleConfig?: Maybe<Scalars['JSON']>;
  taskConfig?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserLinkDomainRelationResponseCollection>;
};


export type DomainInvitesArgs = {
  filters?: InputMaybe<InviteFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type DomainUsersArgs = {
  filters?: InputMaybe<UserLinkDomainFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DomainEntity = {
  __typename?: 'DomainEntity';
  attributes?: Maybe<Domain>;
  id?: Maybe<Scalars['ID']>;
};

export type DomainEntityResponse = {
  __typename?: 'DomainEntityResponse';
  data?: Maybe<DomainEntity>;
};

export type DomainEntityResponseCollection = {
  __typename?: 'DomainEntityResponseCollection';
  data: Array<DomainEntity>;
  meta: ResponseCollectionMeta;
};

export type DomainFiltersInput = {
  activeTill?: InputMaybe<DateFilterInput>;
  activeUsers?: InputMaybe<IntFilterInput>;
  and?: InputMaybe<Array<InputMaybe<DomainFiltersInput>>>;
  blocked?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  demoUsed?: InputMaybe<BooleanFilterInput>;
  enabled?: InputMaybe<BooleanFilterInput>;
  funcConfig?: InputMaybe<JsonFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  invites?: InputMaybe<InviteFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<DomainFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<DomainFiltersInput>>>;
  roleConfig?: InputMaybe<JsonFilterInput>;
  taskConfig?: InputMaybe<JsonFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UserLinkDomainFiltersInput>;
};

export type DomainInput = {
  activeTill?: InputMaybe<Scalars['Date']>;
  activeUsers?: InputMaybe<Scalars['Int']>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  demoUsed?: InputMaybe<Scalars['Boolean']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  funcConfig?: InputMaybe<Scalars['JSON']>;
  invites?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  roleConfig?: InputMaybe<Scalars['JSON']>;
  taskConfig?: InputMaybe<Scalars['JSON']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type DoneResult = {
  __typename?: 'DoneResult';
  done?: Maybe<Scalars['Boolean']>;
};

export enum Enum_Componentfinanceline_Unit {
  Hours = 'hours',
  Items = 'items',
  Minutes = 'minutes'
}

export enum Enum_Contentreleasesreleaseaction_Type {
  Publish = 'publish',
  Unpublish = 'unpublish'
}

export enum Enum_Invite_Status {
  Confirmed_3 = 'CONFIRMED_3',
  Created_1 = 'CREATED_1',
  Error_4 = 'ERROR_4',
  Sended_2 = 'SENDED_2'
}

export enum Enum_Report_Status {
  New = 'new'
}

export type Epic = {
  __typename?: 'Epic';
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  domain?: Maybe<DomainEntityResponse>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EpicEntity = {
  __typename?: 'EpicEntity';
  attributes?: Maybe<Epic>;
  id?: Maybe<Scalars['ID']>;
};

export type EpicEntityResponse = {
  __typename?: 'EpicEntityResponse';
  data?: Maybe<EpicEntity>;
};

export type EpicEntityResponseCollection = {
  __typename?: 'EpicEntityResponseCollection';
  data: Array<EpicEntity>;
  meta: ResponseCollectionMeta;
};

export type EpicFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<EpicFiltersInput>>>;
  color?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  domain?: InputMaybe<DomainFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<EpicFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<EpicFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type EpicInput = {
  color?: InputMaybe<Scalars['String']>;
  domain?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  contains?: InputMaybe<Scalars['Float']>;
  containsi?: InputMaybe<Scalars['Float']>;
  endsWith?: InputMaybe<Scalars['Float']>;
  eq?: InputMaybe<Scalars['Float']>;
  eqi?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
  nei?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<FloatFilterInput>;
  notContains?: InputMaybe<Scalars['Float']>;
  notContainsi?: InputMaybe<Scalars['Float']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  startsWith?: InputMaybe<Scalars['Float']>;
};

export type GenericMorph = Company | ComponentDomainUserInDomain | ComponentFinanceLine | ComponentFinanceProps | ComponentPresentationHeadline | ComponentPresentationMedia | ComponentPresentationText | ComponentTaskMessage | ContentReleasesRelease | ContentReleasesReleaseAction | Domain | Epic | I18NLocale | Invite | Report | Task | Timer | UploadFile | UploadFolder | UserLinkDomain | UsersPermissionsPermission | UsersPermissionsRole | UsersPermissionsUser;

export type I18NLocale = {
  __typename?: 'I18NLocale';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  attributes?: Maybe<I18NLocale>;
  id?: Maybe<Scalars['ID']>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contains?: InputMaybe<Scalars['ID']>;
  containsi?: InputMaybe<Scalars['ID']>;
  endsWith?: InputMaybe<Scalars['ID']>;
  eq?: InputMaybe<Scalars['ID']>;
  eqi?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  nei?: InputMaybe<Scalars['ID']>;
  not?: InputMaybe<IdFilterInput>;
  notContains?: InputMaybe<Scalars['ID']>;
  notContainsi?: InputMaybe<Scalars['ID']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  startsWith?: InputMaybe<Scalars['ID']>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  contains?: InputMaybe<Scalars['Int']>;
  containsi?: InputMaybe<Scalars['Int']>;
  endsWith?: InputMaybe<Scalars['Int']>;
  eq?: InputMaybe<Scalars['Int']>;
  eqi?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
  nei?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<IntFilterInput>;
  notContains?: InputMaybe<Scalars['Int']>;
  notContainsi?: InputMaybe<Scalars['Int']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  startsWith?: InputMaybe<Scalars['Int']>;
};

export type Invite = {
  __typename?: 'Invite';
  UID: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  domain?: Maybe<DomainEntityResponse>;
  email: Scalars['String'];
  status: Enum_Invite_Status;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InviteEntity = {
  __typename?: 'InviteEntity';
  attributes?: Maybe<Invite>;
  id?: Maybe<Scalars['ID']>;
};

export type InviteEntityResponse = {
  __typename?: 'InviteEntityResponse';
  data?: Maybe<InviteEntity>;
};

export type InviteEntityResponseCollection = {
  __typename?: 'InviteEntityResponseCollection';
  data: Array<InviteEntity>;
  meta: ResponseCollectionMeta;
};

export type InviteFiltersInput = {
  UID?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<InviteFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  domain?: InputMaybe<DomainFiltersInput>;
  email?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<InviteFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<InviteFiltersInput>>>;
  status?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type InviteInput = {
  UID?: InputMaybe<Scalars['String']>;
  domain?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Enum_Invite_Status>;
};

export type InviteRelationResponseCollection = {
  __typename?: 'InviteRelationResponseCollection';
  data: Array<InviteEntity>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  contains?: InputMaybe<Scalars['JSON']>;
  containsi?: InputMaybe<Scalars['JSON']>;
  endsWith?: InputMaybe<Scalars['JSON']>;
  eq?: InputMaybe<Scalars['JSON']>;
  eqi?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  ne?: InputMaybe<Scalars['JSON']>;
  nei?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<JsonFilterInput>;
  notContains?: InputMaybe<Scalars['JSON']>;
  notContainsi?: InputMaybe<Scalars['JSON']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  startsWith?: InputMaybe<Scalars['JSON']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvite?: Maybe<DoneResult>;
  activateDemo?: Maybe<DoneResult>;
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  createCompany?: Maybe<CompanyEntityResponse>;
  createContentReleasesRelease?: Maybe<ContentReleasesReleaseEntityResponse>;
  createContentReleasesReleaseAction?: Maybe<ContentReleasesReleaseActionEntityResponse>;
  createDomain?: Maybe<DomainEntityResponse>;
  createEpic?: Maybe<EpicEntityResponse>;
  createInvite?: Maybe<InviteEntityResponse>;
  createReport?: Maybe<ReportEntityResponse>;
  createTask?: Maybe<TaskEntityResponse>;
  createTimer?: Maybe<TimerEntityResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  createUserLinkDomain?: Maybe<UserLinkDomainEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteCompany?: Maybe<CompanyEntityResponse>;
  deleteContentReleasesRelease?: Maybe<ContentReleasesReleaseEntityResponse>;
  deleteContentReleasesReleaseAction?: Maybe<ContentReleasesReleaseActionEntityResponse>;
  deleteDomain?: Maybe<DomainEntityResponse>;
  deleteEpic?: Maybe<EpicEntityResponse>;
  deleteInvite?: Maybe<InviteEntityResponse>;
  deleteReport?: Maybe<ReportEntityResponse>;
  deleteTask?: Maybe<TaskEntityResponse>;
  deleteTimer?: Maybe<TimerEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  deleteUserLinkDomain?: Maybe<UserLinkDomainEntityResponse>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  sendPasscode?: Maybe<SendPasscodeResponse>;
  updateCompany?: Maybe<CompanyEntityResponse>;
  updateContentReleasesRelease?: Maybe<ContentReleasesReleaseEntityResponse>;
  updateContentReleasesReleaseAction?: Maybe<ContentReleasesReleaseActionEntityResponse>;
  updateDomain?: Maybe<DomainEntityResponse>;
  updateEpic?: Maybe<EpicEntityResponse>;
  updateFileInfo: UploadFileEntityResponse;
  updateInvite?: Maybe<InviteEntityResponse>;
  updateReport?: Maybe<ReportEntityResponse>;
  updateTask?: Maybe<TaskEntityResponse>;
  updateTimer?: Maybe<TimerEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  updateUserLinkDomain?: Maybe<UserLinkDomainEntityResponse>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  upload: UploadFileEntityResponse;
};


export type MutationAcceptInviteArgs = {
  id: Scalars['ID'];
};


export type MutationActivateDemoArgs = {
  id: Scalars['ID'];
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationCreateCompanyArgs = {
  data: CompanyInput;
};


export type MutationCreateContentReleasesReleaseArgs = {
  data: ContentReleasesReleaseInput;
};


export type MutationCreateContentReleasesReleaseActionArgs = {
  data: ContentReleasesReleaseActionInput;
};


export type MutationCreateDomainArgs = {
  data: DomainInput;
};


export type MutationCreateEpicArgs = {
  data: EpicInput;
};


export type MutationCreateInviteArgs = {
  data: InviteInput;
};


export type MutationCreateReportArgs = {
  data: ReportInput;
};


export type MutationCreateTaskArgs = {
  data: TaskInput;
};


export type MutationCreateTimerArgs = {
  data: TimerInput;
};


export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};


export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};


export type MutationCreateUserLinkDomainArgs = {
  data: UserLinkDomainInput;
};


export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};


export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteContentReleasesReleaseArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteContentReleasesReleaseActionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDomainArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEpicArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteInviteArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteReportArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTaskArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTimerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserLinkDomainArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID'];
};


export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};


export type MutationMultipleUploadArgs = {
  field?: InputMaybe<Scalars['String']>;
  files: Array<InputMaybe<Scalars['Upload']>>;
  ref?: InputMaybe<Scalars['String']>;
  refId?: InputMaybe<Scalars['ID']>;
};


export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};


export type MutationRemoveFileArgs = {
  id: Scalars['ID'];
};


export type MutationResetPasswordArgs = {
  code: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationSendPasscodeArgs = {
  email: Scalars['String'];
};


export type MutationUpdateCompanyArgs = {
  data: CompanyInput;
  id: Scalars['ID'];
};


export type MutationUpdateContentReleasesReleaseArgs = {
  data: ContentReleasesReleaseInput;
  id: Scalars['ID'];
};


export type MutationUpdateContentReleasesReleaseActionArgs = {
  data: ContentReleasesReleaseActionInput;
  id: Scalars['ID'];
};


export type MutationUpdateDomainArgs = {
  data: DomainInput;
  id: Scalars['ID'];
};


export type MutationUpdateEpicArgs = {
  data: EpicInput;
  id: Scalars['ID'];
};


export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID'];
  info?: InputMaybe<FileInfoInput>;
};


export type MutationUpdateInviteArgs = {
  data: InviteInput;
  id: Scalars['ID'];
};


export type MutationUpdateReportArgs = {
  data: ReportInput;
  id: Scalars['ID'];
};


export type MutationUpdateTaskArgs = {
  data: TaskInput;
  id: Scalars['ID'];
};


export type MutationUpdateTimerArgs = {
  data: TimerInput;
  id: Scalars['ID'];
};


export type MutationUpdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars['ID'];
};


export type MutationUpdateUploadFolderArgs = {
  data: UploadFolderInput;
  id: Scalars['ID'];
};


export type MutationUpdateUserLinkDomainArgs = {
  data: UserLinkDomainInput;
  id: Scalars['ID'];
};


export type MutationUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars['ID'];
};


export type MutationUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars['ID'];
};


export type MutationUploadArgs = {
  field?: InputMaybe<Scalars['String']>;
  file: Scalars['Upload'];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars['String']>;
  refId?: InputMaybe<Scalars['ID']>;
};

export type MyDomains = {
  __typename?: 'MyDomains';
  activeTill?: Maybe<Scalars['Date']>;
  activeUsers: Scalars['Int'];
  blocked: Scalars['Boolean'];
  demoUsed: Scalars['Boolean'];
  funcConfig: Scalars['JSON'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isAdmin: Scalars['Boolean'];
  name: Scalars['String'];
  taskConfig: Scalars['JSON'];
};

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type PaginationArg = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['Int']>;
};

export type Person = {
  __typename?: 'Person';
  active?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  username: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  checkEmail?: Maybe<CheckEmailResponse>;
  checkUsername?: Maybe<CheckUsernameResponse>;
  companies?: Maybe<CompanyEntityResponseCollection>;
  company?: Maybe<CompanyEntityResponse>;
  contentReleasesRelease?: Maybe<ContentReleasesReleaseEntityResponse>;
  contentReleasesReleaseAction?: Maybe<ContentReleasesReleaseActionEntityResponse>;
  contentReleasesReleaseActions?: Maybe<ContentReleasesReleaseActionEntityResponseCollection>;
  contentReleasesReleases?: Maybe<ContentReleasesReleaseEntityResponseCollection>;
  domain?: Maybe<DomainEntityResponse>;
  domains?: Maybe<DomainEntityResponseCollection>;
  epic?: Maybe<EpicEntityResponse>;
  epics?: Maybe<EpicEntityResponseCollection>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  invite?: Maybe<InviteEntityResponse>;
  invites?: Maybe<InviteEntityResponseCollection>;
  me?: Maybe<UsersPermissionsMe>;
  report?: Maybe<ReportEntityResponse>;
  reports?: Maybe<ReportEntityResponseCollection>;
  task?: Maybe<TaskEntityResponse>;
  tasks?: Maybe<TaskEntityResponseCollection>;
  team?: Maybe<TeamResponse>;
  timer?: Maybe<TimerEntityResponse>;
  timers?: Maybe<TimerEntityResponseCollection>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  userLinkDomain?: Maybe<UserLinkDomainEntityResponse>;
  userLinkDomains?: Maybe<UserLinkDomainEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
};


export type QueryCheckEmailArgs = {
  email: Scalars['String'];
};


export type QueryCheckUsernameArgs = {
  username: Scalars['String'];
};


export type QueryCompaniesArgs = {
  filters?: InputMaybe<CompanyFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCompanyArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryContentReleasesReleaseArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryContentReleasesReleaseActionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryContentReleasesReleaseActionsArgs = {
  filters?: InputMaybe<ContentReleasesReleaseActionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryContentReleasesReleasesArgs = {
  filters?: InputMaybe<ContentReleasesReleaseFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryDomainArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryDomainsArgs = {
  filters?: InputMaybe<DomainFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryEpicArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryEpicsArgs = {
  filters?: InputMaybe<EpicFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryI18NLocaleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryInviteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryInvitesArgs = {
  filters?: InputMaybe<InviteFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryReportArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryReportsArgs = {
  filters?: InputMaybe<ReportFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryTaskArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryTasksArgs = {
  filters?: InputMaybe<TaskFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryTeamArgs = {
  domain: Scalars['ID'];
};


export type QueryTimerArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryTimersArgs = {
  filters?: InputMaybe<TimerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUploadFileArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUploadFolderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUserLinkDomainArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUserLinkDomainsArgs = {
  filters?: InputMaybe<UserLinkDomainFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Report = {
  __typename?: 'Report';
  author?: Maybe<UsersPermissionsUserEntityResponse>;
  comment: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  domain?: Maybe<DomainEntityResponse>;
  minutes: Scalars['Int'];
  status: Enum_Report_Status;
  task?: Maybe<TaskEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReportEntity = {
  __typename?: 'ReportEntity';
  attributes?: Maybe<Report>;
  id?: Maybe<Scalars['ID']>;
};

export type ReportEntityResponse = {
  __typename?: 'ReportEntityResponse';
  data?: Maybe<ReportEntity>;
};

export type ReportEntityResponseCollection = {
  __typename?: 'ReportEntityResponseCollection';
  data: Array<ReportEntity>;
  meta: ResponseCollectionMeta;
};

export type ReportFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ReportFiltersInput>>>;
  author?: InputMaybe<UsersPermissionsUserFiltersInput>;
  comment?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  domain?: InputMaybe<DomainFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  minutes?: InputMaybe<IntFilterInput>;
  not?: InputMaybe<ReportFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ReportFiltersInput>>>;
  status?: InputMaybe<StringFilterInput>;
  task?: InputMaybe<TaskFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ReportInput = {
  author?: InputMaybe<Scalars['ID']>;
  comment?: InputMaybe<Scalars['String']>;
  domain?: InputMaybe<Scalars['ID']>;
  minutes?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Enum_Report_Status>;
  task?: InputMaybe<Scalars['ID']>;
};

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contains?: InputMaybe<Scalars['String']>;
  containsi?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  eqi?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  nei?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<StringFilterInput>;
  notContains?: InputMaybe<Scalars['String']>;
  notContainsi?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Task = {
  __typename?: 'Task';
  body?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyEntityResponse>;
  content?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  domain?: Maybe<DomainEntityResponse>;
  enabled: Scalars['Boolean'];
  epic?: Maybe<EpicEntityResponse>;
  name: Scalars['String'];
  reportsMinutes?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  timersSeconds?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};


export type TaskUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TaskEntity = {
  __typename?: 'TaskEntity';
  attributes?: Maybe<Task>;
  id?: Maybe<Scalars['ID']>;
};

export type TaskEntityResponse = {
  __typename?: 'TaskEntityResponse';
  data?: Maybe<TaskEntity>;
};

export type TaskEntityResponseCollection = {
  __typename?: 'TaskEntityResponseCollection';
  data: Array<TaskEntity>;
  meta: ResponseCollectionMeta;
};

export type TaskFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<TaskFiltersInput>>>;
  body?: InputMaybe<StringFilterInput>;
  company?: InputMaybe<CompanyFiltersInput>;
  content?: InputMaybe<JsonFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  domain?: InputMaybe<DomainFiltersInput>;
  enabled?: InputMaybe<BooleanFilterInput>;
  epic?: InputMaybe<EpicFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<TaskFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<TaskFiltersInput>>>;
  status?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type TaskInput = {
  body?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['ID']>;
  content?: InputMaybe<Scalars['JSON']>;
  domain?: InputMaybe<Scalars['ID']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  epic?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type TeamResponse = {
  __typename?: 'TeamResponse';
  data: Array<Person>;
};

export type Timer = {
  __typename?: 'Timer';
  createdAt?: Maybe<Scalars['DateTime']>;
  domain?: Maybe<DomainEntityResponse>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  task?: Maybe<TaskEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UsersPermissionsUserEntityResponse>;
};

export type TimerEntity = {
  __typename?: 'TimerEntity';
  attributes?: Maybe<Timer>;
  id?: Maybe<Scalars['ID']>;
};

export type TimerEntityResponse = {
  __typename?: 'TimerEntityResponse';
  data?: Maybe<TimerEntity>;
};

export type TimerEntityResponseCollection = {
  __typename?: 'TimerEntityResponseCollection';
  data: Array<TimerEntity>;
  meta: ResponseCollectionMeta;
};

export type TimerFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<TimerFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  domain?: InputMaybe<DomainFiltersInput>;
  finishedAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<TimerFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<TimerFiltersInput>>>;
  task?: InputMaybe<TaskFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  user?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type TimerInput = {
  domain?: InputMaybe<Scalars['ID']>;
  finishedAt?: InputMaybe<Scalars['DateTime']>;
  task?: InputMaybe<Scalars['ID']>;
  user?: InputMaybe<Scalars['ID']>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ext?: Maybe<Scalars['String']>;
  formats?: Maybe<Scalars['JSON']>;
  hash: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  mime: Scalars['String'];
  name: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata?: Maybe<Scalars['JSON']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  attributes?: Maybe<UploadFile>;
  id?: Maybe<Scalars['ID']>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  alternativeText?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  caption?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFileFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  ext?: InputMaybe<Scalars['String']>;
  folder?: InputMaybe<Scalars['ID']>;
  folderPath?: InputMaybe<Scalars['String']>;
  formats?: InputMaybe<Scalars['JSON']>;
  hash?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  mime?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  previewUrl?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  provider_metadata?: InputMaybe<Scalars['JSON']>;
  size?: InputMaybe<Scalars['Float']>;
  url?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  children?: Maybe<UploadFolderRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars['String'];
  parent?: Maybe<UploadFolderEntityResponse>;
  path: Scalars['String'];
  pathId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type UploadFolderChildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UploadFolderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  attributes?: Maybe<UploadFolder>;
  id?: Maybe<Scalars['ID']>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFolderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['ID']>;
  path?: InputMaybe<Scalars['String']>;
  pathId?: InputMaybe<Scalars['Int']>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type UserLinkDomain = {
  __typename?: 'UserLinkDomain';
  active: Scalars['Boolean'];
  admin: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  domain?: Maybe<DomainEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UsersPermissionsUserEntityResponse>;
};

export type UserLinkDomainEntity = {
  __typename?: 'UserLinkDomainEntity';
  attributes?: Maybe<UserLinkDomain>;
  id?: Maybe<Scalars['ID']>;
};

export type UserLinkDomainEntityResponse = {
  __typename?: 'UserLinkDomainEntityResponse';
  data?: Maybe<UserLinkDomainEntity>;
};

export type UserLinkDomainEntityResponseCollection = {
  __typename?: 'UserLinkDomainEntityResponseCollection';
  data: Array<UserLinkDomainEntity>;
  meta: ResponseCollectionMeta;
};

export type UserLinkDomainFiltersInput = {
  active?: InputMaybe<BooleanFilterInput>;
  admin?: InputMaybe<BooleanFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UserLinkDomainFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  domain?: InputMaybe<DomainFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UserLinkDomainFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UserLinkDomainFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  user?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UserLinkDomainInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  admin?: InputMaybe<Scalars['Boolean']>;
  domain?: InputMaybe<Scalars['ID']>;
  user?: InputMaybe<Scalars['ID']>;
};

export type UserLinkDomainRelationResponseCollection = {
  __typename?: 'UserLinkDomainRelationResponseCollection';
  data: Array<UserLinkDomainEntity>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String'];
  password: Scalars['String'];
  provider?: Scalars['String'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  blocked?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  domains: Array<Maybe<MyDomains>>;
  email?: Maybe<Scalars['String']>;
  focusedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String'];
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  attributes?: Maybe<UsersPermissionsPermission>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsPermissionFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};


export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  attributes?: Maybe<UsersPermissionsRole>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  type?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  blocked?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  focusedAt?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<Scalars['String']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  attributes?: Maybe<UsersPermissionsUser>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  blocked?: InputMaybe<BooleanFilterInput>;
  color?: InputMaybe<StringFilterInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  focusedAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  password?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  username?: InputMaybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
  blocked?: InputMaybe<Scalars['Boolean']>;
  color?: InputMaybe<Scalars['String']>;
  confirmationToken?: InputMaybe<Scalars['String']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  focusedAt?: InputMaybe<Scalars['DateTime']>;
  password?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  resetPasswordToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['ID']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type CheckEmailResponse = {
  __typename?: 'checkEmailResponse';
  valid?: Maybe<Scalars['Boolean']>;
};

export type CheckUsernameResponse = {
  __typename?: 'checkUsernameResponse';
  valid?: Maybe<Scalars['Boolean']>;
};

export type SendPasscodeResponse = {
  __typename?: 'sendPasscodeResponse';
  done?: Maybe<Scalars['Boolean']>;
};

export type TeamQueryVariables = Exact<{
  domain: Scalars['ID'];
}>;


export type TeamQuery = { __typename?: 'Query', team?: { __typename?: 'TeamResponse', data: Array<{ __typename?: 'Person', id?: string | null, username: string, color?: string | null }> } | null };

export type ReportsQueryVariables = Exact<{
  filters?: InputMaybe<ReportFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type ReportsQuery = { __typename?: 'Query', reports?: { __typename?: 'ReportEntityResponseCollection', data: Array<{ __typename?: 'ReportEntity', id?: string | null, attributes?: { __typename?: 'Report', status: Enum_Report_Status, minutes: number, comment: string, author?: { __typename?: 'UsersPermissionsUserEntityResponse', data?: { __typename?: 'UsersPermissionsUserEntity', id?: string | null, attributes?: { __typename?: 'UsersPermissionsUser', username: string, color?: string | null } | null } | null } | null } | null }> } | null };

export type CreateReportMutationVariables = Exact<{
  data: ReportInput;
}>;


export type CreateReportMutation = { __typename?: 'Mutation', createReport?: { __typename?: 'ReportEntityResponse', data?: { __typename?: 'ReportEntity', id?: string | null } | null } | null };

export type TimersQueryVariables = Exact<{
  filters?: InputMaybe<TimerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type TimersQuery = { __typename?: 'Query', timers?: { __typename?: 'TimerEntityResponseCollection', data: Array<{ __typename?: 'TimerEntity', id?: string | null, attributes?: { __typename?: 'Timer', createdAt?: any | null, finishedAt?: any | null, user?: { __typename?: 'UsersPermissionsUserEntityResponse', data?: { __typename?: 'UsersPermissionsUserEntity', id?: string | null, attributes?: { __typename?: 'UsersPermissionsUser', username: string, color?: string | null } | null } | null } | null, task?: { __typename?: 'TaskEntityResponse', data?: { __typename?: 'TaskEntity', id?: string | null } | null } | null } | null }> } | null };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', me?: { __typename?: 'UsersPermissionsMe', id: string, email?: string | null, username: string, blocked?: boolean | null, color?: string | null, focusedAt?: any | null, role?: { __typename?: 'UsersPermissionsMeRole', id: string, name: string } | null, domains: Array<{ __typename?: 'MyDomains', id: string, name: string, isAdmin: boolean, isActive: boolean, blocked: boolean, activeUsers: number, activeTill?: any | null, demoUsed: boolean, taskConfig: any, funcConfig: any } | null> } | null };

export type LoginMutationVariables = Exact<{
  input: UsersPermissionsLoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'UsersPermissionsLoginPayload', jwt?: string | null, user: { __typename?: 'UsersPermissionsMe', id: string, blocked?: boolean | null } } };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword?: { __typename?: 'UsersPermissionsPasswordPayload', ok: boolean } | null };

export type ResetPasswordMutationVariables = Exact<{
  code: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: { __typename?: 'UsersPermissionsLoginPayload', jwt?: string | null, user: { __typename?: 'UsersPermissionsMe', id: string, blocked?: boolean | null } } | null };

export type RegisterMutationVariables = Exact<{
  input: UsersPermissionsRegisterInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'UsersPermissionsLoginPayload', jwt?: string | null, user: { __typename?: 'UsersPermissionsMe', id: string } } };

export type CheckEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type CheckEmailQuery = { __typename?: 'Query', checkEmail?: { __typename?: 'checkEmailResponse', valid?: boolean | null } | null };

export type CheckUsernameQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type CheckUsernameQuery = { __typename?: 'Query', checkUsername?: { __typename?: 'checkUsernameResponse', valid?: boolean | null } | null };

export type CompaniesQueryVariables = Exact<{
  filters?: InputMaybe<CompanyFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type CompaniesQuery = { __typename?: 'Query', companies?: { __typename?: 'CompanyEntityResponseCollection', data: Array<{ __typename?: 'CompanyEntity', id?: string | null, attributes?: { __typename?: 'Company', name: string, color?: string | null, enabled: boolean } | null }> } | null };

export type CreateCompanyMutationVariables = Exact<{
  data: CompanyInput;
}>;


export type CreateCompanyMutation = { __typename?: 'Mutation', createCompany?: { __typename?: 'CompanyEntityResponse', data?: { __typename?: 'CompanyEntity', id?: string | null } | null } | null };

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
  data: CompanyInput;
}>;


export type UpdateCompanyMutation = { __typename?: 'Mutation', updateCompany?: { __typename?: 'CompanyEntityResponse', data?: { __typename?: 'CompanyEntity', id?: string | null } | null } | null };

export type DeleteCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCompanyMutation = { __typename?: 'Mutation', deleteCompany?: { __typename?: 'CompanyEntityResponse', data?: { __typename?: 'CompanyEntity', id?: string | null } | null } | null };

export type DomainQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DomainQuery = { __typename?: 'Query', domain?: { __typename?: 'DomainEntityResponse', data?: { __typename?: 'DomainEntity', id?: string | null, attributes?: { __typename?: 'Domain', name: string, enabled: boolean, blocked: boolean, activeTill?: any | null, activeUsers: number, users?: { __typename?: 'UserLinkDomainRelationResponseCollection', data: Array<{ __typename?: 'UserLinkDomainEntity', id?: string | null, attributes?: { __typename?: 'UserLinkDomain', active: boolean, admin: boolean, user?: { __typename?: 'UsersPermissionsUserEntityResponse', data?: { __typename?: 'UsersPermissionsUserEntity', id?: string | null, attributes?: { __typename?: 'UsersPermissionsUser', username: string, color?: string | null } | null } | null } | null } | null }> } | null } | null } | null } | null };

export type CreateDomainMutationVariables = Exact<{
  data: DomainInput;
}>;


export type CreateDomainMutation = { __typename?: 'Mutation', createDomain?: { __typename?: 'DomainEntityResponse', data?: { __typename?: 'DomainEntity', id?: string | null, attributes?: { __typename?: 'Domain', name: string, blocked: boolean, activeTill?: any | null, activeUsers: number, demoUsed: boolean, funcConfig?: any | null, roleConfig?: any | null, taskConfig?: any | null } | null } | null } | null };

export type CreateInviteMutationVariables = Exact<{
  data: InviteInput;
}>;


export type CreateInviteMutation = { __typename?: 'Mutation', createInvite?: { __typename?: 'InviteEntityResponse', data?: { __typename?: 'InviteEntity', id?: string | null, attributes?: { __typename?: 'Invite', status: Enum_Invite_Status } | null } | null } | null };

export type DeleteInviteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteInviteMutation = { __typename?: 'Mutation', deleteInvite?: { __typename?: 'InviteEntityResponse', data?: { __typename?: 'InviteEntity', id?: string | null } | null } | null };

export type ActivateDemoMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ActivateDemoMutation = { __typename?: 'Mutation', activateDemo?: { __typename?: 'DoneResult', done?: boolean | null } | null };

export type UpdateDomainMutationVariables = Exact<{
  id: Scalars['ID'];
  data: DomainInput;
}>;


export type UpdateDomainMutation = { __typename?: 'Mutation', updateDomain?: { __typename?: 'DomainEntityResponse', data?: { __typename?: 'DomainEntity', id?: string | null } | null } | null };

export type InvitesQueryVariables = Exact<{
  filters?: InputMaybe<InviteFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type InvitesQuery = { __typename?: 'Query', invites?: { __typename?: 'InviteEntityResponseCollection', data: Array<{ __typename?: 'InviteEntity', id?: string | null, attributes?: { __typename?: 'Invite', email: string, status: Enum_Invite_Status } | null }> } | null };

export type DeleteUserLinkDomainMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserLinkDomainMutation = { __typename?: 'Mutation', deleteUserLinkDomain?: { __typename?: 'UserLinkDomainEntityResponse', data?: { __typename?: 'UserLinkDomainEntity', id?: string | null } | null } | null };

export type UpdateUserLinkDomainMutationVariables = Exact<{
  id: Scalars['ID'];
  data: UserLinkDomainInput;
}>;


export type UpdateUserLinkDomainMutation = { __typename?: 'Mutation', updateUserLinkDomain?: { __typename?: 'UserLinkDomainEntityResponse', data?: { __typename?: 'UserLinkDomainEntity', id?: string | null } | null } | null };

export type EpicsQueryVariables = Exact<{
  filters?: InputMaybe<EpicFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type EpicsQuery = { __typename?: 'Query', epics?: { __typename?: 'EpicEntityResponseCollection', data: Array<{ __typename?: 'EpicEntity', id?: string | null, attributes?: { __typename?: 'Epic', name: string, color: string } | null }> } | null };

export type CreateEpicMutationVariables = Exact<{
  data: EpicInput;
}>;


export type CreateEpicMutation = { __typename?: 'Mutation', createEpic?: { __typename?: 'EpicEntityResponse', data?: { __typename?: 'EpicEntity', id?: string | null } | null } | null };

export type UpdateEpicMutationVariables = Exact<{
  id: Scalars['ID'];
  data: EpicInput;
}>;


export type UpdateEpicMutation = { __typename?: 'Mutation', updateEpic?: { __typename?: 'EpicEntityResponse', data?: { __typename?: 'EpicEntity', id?: string | null } | null } | null };

export type DeleteEpicMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteEpicMutation = { __typename?: 'Mutation', deleteEpic?: { __typename?: 'EpicEntityResponse', data?: { __typename?: 'EpicEntity', id?: string | null } | null } | null };

export type TasksQueryVariables = Exact<{
  filters?: InputMaybe<TaskFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type TasksQuery = { __typename?: 'Query', tasks?: { __typename?: 'TaskEntityResponseCollection', data: Array<{ __typename?: 'TaskEntity', id?: string | null, attributes?: { __typename?: 'Task', name: string, status: string, reportsMinutes?: number | null, timersSeconds?: number | null, users?: { __typename?: 'UsersPermissionsUserRelationResponseCollection', data: Array<{ __typename?: 'UsersPermissionsUserEntity', id?: string | null, attributes?: { __typename?: 'UsersPermissionsUser', username: string, color?: string | null } | null }> } | null, company?: { __typename?: 'CompanyEntityResponse', data?: { __typename?: 'CompanyEntity', id?: string | null, attributes?: { __typename?: 'Company', name: string, color?: string | null } | null } | null } | null, epic?: { __typename?: 'EpicEntityResponse', data?: { __typename?: 'EpicEntity', id?: string | null, attributes?: { __typename?: 'Epic', name: string, color: string } | null } | null } | null } | null }> } | null };

export type CreateTaskMutationVariables = Exact<{
  data: TaskInput;
}>;


export type CreateTaskMutation = { __typename?: 'Mutation', createTask?: { __typename?: 'TaskEntityResponse', data?: { __typename?: 'TaskEntity', id?: string | null } | null } | null };

export type UpdateTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  data: TaskInput;
}>;


export type UpdateTaskMutation = { __typename?: 'Mutation', updateTask?: { __typename?: 'TaskEntityResponse', data?: { __typename?: 'TaskEntity', id?: string | null } | null } | null };

export type InviteQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type InviteQuery = { __typename?: 'Query', invite?: { __typename?: 'InviteEntityResponse', data?: { __typename?: 'InviteEntity', id?: string | null, attributes?: { __typename?: 'Invite', email: string, status: Enum_Invite_Status, domain?: { __typename?: 'DomainEntityResponse', data?: { __typename?: 'DomainEntity', id?: string | null, attributes?: { __typename?: 'Domain', name: string } | null } | null } | null } | null } | null } | null };

export type AcceptInviteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AcceptInviteMutation = { __typename?: 'Mutation', acceptInvite?: { __typename?: 'DoneResult', done?: boolean | null } | null };

export type TaskQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;


export type TaskQuery = { __typename?: 'Query', task?: { __typename?: 'TaskEntityResponse', data?: { __typename?: 'TaskEntity', id?: string | null, attributes?: { __typename?: 'Task', name: string, status: string, body?: string | null, content?: any | null, users?: { __typename?: 'UsersPermissionsUserRelationResponseCollection', data: Array<{ __typename?: 'UsersPermissionsUserEntity', id?: string | null, attributes?: { __typename?: 'UsersPermissionsUser', username: string, color?: string | null } | null }> } | null, company?: { __typename?: 'CompanyEntityResponse', data?: { __typename?: 'CompanyEntity', id?: string | null, attributes?: { __typename?: 'Company', name: string, color?: string | null } | null } | null } | null, epic?: { __typename?: 'EpicEntityResponse', data?: { __typename?: 'EpicEntity', id?: string | null, attributes?: { __typename?: 'Epic', name: string, color: string } | null } | null } | null } | null } | null } | null };


export const TeamDocument = gql`
    query team($domain: ID!) {
  team(domain: $domain) {
    data {
      id
      username
      color
    }
  }
}
    `;

/**
 * __useTeamQuery__
 *
 * To run a query within a React component, call `useTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useTeamQuery(baseOptions: Apollo.QueryHookOptions<TeamQuery, TeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamQuery, TeamQueryVariables>(TeamDocument, options);
      }
export function useTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamQuery, TeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamQuery, TeamQueryVariables>(TeamDocument, options);
        }
export type TeamQueryHookResult = ReturnType<typeof useTeamQuery>;
export type TeamLazyQueryHookResult = ReturnType<typeof useTeamLazyQuery>;
export type TeamQueryResult = Apollo.QueryResult<TeamQuery, TeamQueryVariables>;
export const ReportsDocument = gql`
    query reports($filters: ReportFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  reports(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        author {
          data {
            id
            attributes {
              username
              color
            }
          }
        }
        status
        minutes
        comment
      }
    }
  }
}
    `;

/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReportsQuery(baseOptions?: Apollo.QueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
      }
export function useReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
        }
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>;
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>;
export type ReportsQueryResult = Apollo.QueryResult<ReportsQuery, ReportsQueryVariables>;
export const CreateReportDocument = gql`
    mutation createReport($data: ReportInput!) {
  createReport(data: $data) {
    data {
      id
    }
  }
}
    `;
export type CreateReportMutationFn = Apollo.MutationFunction<CreateReportMutation, CreateReportMutationVariables>;

/**
 * __useCreateReportMutation__
 *
 * To run a mutation, you first call `useCreateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportMutation, { data, loading, error }] = useCreateReportMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportMutation, CreateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportMutation, CreateReportMutationVariables>(CreateReportDocument, options);
      }
export type CreateReportMutationHookResult = ReturnType<typeof useCreateReportMutation>;
export type CreateReportMutationResult = Apollo.MutationResult<CreateReportMutation>;
export type CreateReportMutationOptions = Apollo.BaseMutationOptions<CreateReportMutation, CreateReportMutationVariables>;
export const TimersDocument = gql`
    query timers($filters: TimerFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  timers(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        createdAt
        finishedAt
        user {
          data {
            id
            attributes {
              username
              color
            }
          }
        }
        task {
          data {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useTimersQuery__
 *
 * To run a query within a React component, call `useTimersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useTimersQuery(baseOptions?: Apollo.QueryHookOptions<TimersQuery, TimersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimersQuery, TimersQueryVariables>(TimersDocument, options);
      }
export function useTimersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimersQuery, TimersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimersQuery, TimersQueryVariables>(TimersDocument, options);
        }
export type TimersQueryHookResult = ReturnType<typeof useTimersQuery>;
export type TimersLazyQueryHookResult = ReturnType<typeof useTimersLazyQuery>;
export type TimersQueryResult = Apollo.QueryResult<TimersQuery, TimersQueryVariables>;
export const GetMeDocument = gql`
    query getMe {
  me {
    id
    email
    username
    blocked
    role {
      id
      name
    }
    domains {
      id
      name
      isAdmin
      isActive
      blocked
      activeUsers
      activeTill
      demoUsed
      taskConfig
      funcConfig
    }
    color
    focusedAt
  }
}
    `;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const LoginDocument = gql`
    mutation login($input: UsersPermissionsLoginInput!) {
  login(input: $input) {
    jwt
    user {
      id
      blocked
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email) {
    ok
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($code: String!, $password: String!, $passwordConfirmation: String!) {
  resetPassword(
    code: $code
    password: $password
    passwordConfirmation: $passwordConfirmation
  ) {
    jwt
    user {
      id
      blocked
    }
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      code: // value for 'code'
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const RegisterDocument = gql`
    mutation register($input: UsersPermissionsRegisterInput!) {
  register(input: $input) {
    jwt
    user {
      id
    }
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const CheckEmailDocument = gql`
    query checkEmail($email: String!) {
  checkEmail(email: $email) {
    valid
  }
}
    `;

/**
 * __useCheckEmailQuery__
 *
 * To run a query within a React component, call `useCheckEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckEmailQuery(baseOptions: Apollo.QueryHookOptions<CheckEmailQuery, CheckEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckEmailQuery, CheckEmailQueryVariables>(CheckEmailDocument, options);
      }
export function useCheckEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckEmailQuery, CheckEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckEmailQuery, CheckEmailQueryVariables>(CheckEmailDocument, options);
        }
export type CheckEmailQueryHookResult = ReturnType<typeof useCheckEmailQuery>;
export type CheckEmailLazyQueryHookResult = ReturnType<typeof useCheckEmailLazyQuery>;
export type CheckEmailQueryResult = Apollo.QueryResult<CheckEmailQuery, CheckEmailQueryVariables>;
export const CheckUsernameDocument = gql`
    query checkUsername($username: String!) {
  checkUsername(username: $username) {
    valid
  }
}
    `;

/**
 * __useCheckUsernameQuery__
 *
 * To run a query within a React component, call `useCheckUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useCheckUsernameQuery(baseOptions: Apollo.QueryHookOptions<CheckUsernameQuery, CheckUsernameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckUsernameQuery, CheckUsernameQueryVariables>(CheckUsernameDocument, options);
      }
export function useCheckUsernameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckUsernameQuery, CheckUsernameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckUsernameQuery, CheckUsernameQueryVariables>(CheckUsernameDocument, options);
        }
export type CheckUsernameQueryHookResult = ReturnType<typeof useCheckUsernameQuery>;
export type CheckUsernameLazyQueryHookResult = ReturnType<typeof useCheckUsernameLazyQuery>;
export type CheckUsernameQueryResult = Apollo.QueryResult<CheckUsernameQuery, CheckUsernameQueryVariables>;
export const CompaniesDocument = gql`
    query companies($filters: CompanyFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  companies(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        name
        color
        enabled
      }
    }
  }
}
    `;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
      }
export function useCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesQueryResult = Apollo.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($data: CompanyInput!) {
  createCompany(data: $data) {
    data {
      id
    }
  }
}
    `;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($id: ID!, $data: CompanyInput!) {
  updateCompany(id: $id, data: $data) {
    data {
      id
    }
  }
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation deleteCompany($id: ID!) {
  deleteCompany(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, options);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const DomainDocument = gql`
    query domain($id: ID!) {
  domain(id: $id) {
    data {
      id
      attributes {
        name
        enabled
        blocked
        activeTill
        activeUsers
        users {
          data {
            id
            attributes {
              user {
                data {
                  id
                  attributes {
                    username
                    color
                  }
                }
              }
              active
              admin
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDomainQuery__
 *
 * To run a query within a React component, call `useDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDomainQuery(baseOptions: Apollo.QueryHookOptions<DomainQuery, DomainQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DomainQuery, DomainQueryVariables>(DomainDocument, options);
      }
export function useDomainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DomainQuery, DomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DomainQuery, DomainQueryVariables>(DomainDocument, options);
        }
export type DomainQueryHookResult = ReturnType<typeof useDomainQuery>;
export type DomainLazyQueryHookResult = ReturnType<typeof useDomainLazyQuery>;
export type DomainQueryResult = Apollo.QueryResult<DomainQuery, DomainQueryVariables>;
export const CreateDomainDocument = gql`
    mutation createDomain($data: DomainInput!) {
  createDomain(data: $data) {
    data {
      id
      attributes {
        name
        blocked
        activeTill
        activeUsers
        demoUsed
        funcConfig
        roleConfig
        taskConfig
      }
    }
  }
}
    `;
export type CreateDomainMutationFn = Apollo.MutationFunction<CreateDomainMutation, CreateDomainMutationVariables>;

/**
 * __useCreateDomainMutation__
 *
 * To run a mutation, you first call `useCreateDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDomainMutation, { data, loading, error }] = useCreateDomainMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDomainMutation(baseOptions?: Apollo.MutationHookOptions<CreateDomainMutation, CreateDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDomainMutation, CreateDomainMutationVariables>(CreateDomainDocument, options);
      }
export type CreateDomainMutationHookResult = ReturnType<typeof useCreateDomainMutation>;
export type CreateDomainMutationResult = Apollo.MutationResult<CreateDomainMutation>;
export type CreateDomainMutationOptions = Apollo.BaseMutationOptions<CreateDomainMutation, CreateDomainMutationVariables>;
export const CreateInviteDocument = gql`
    mutation createInvite($data: InviteInput!) {
  createInvite(data: $data) {
    data {
      id
      attributes {
        status
      }
    }
  }
}
    `;
export type CreateInviteMutationFn = Apollo.MutationFunction<CreateInviteMutation, CreateInviteMutationVariables>;

/**
 * __useCreateInviteMutation__
 *
 * To run a mutation, you first call `useCreateInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInviteMutation, { data, loading, error }] = useCreateInviteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateInviteMutation(baseOptions?: Apollo.MutationHookOptions<CreateInviteMutation, CreateInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInviteMutation, CreateInviteMutationVariables>(CreateInviteDocument, options);
      }
export type CreateInviteMutationHookResult = ReturnType<typeof useCreateInviteMutation>;
export type CreateInviteMutationResult = Apollo.MutationResult<CreateInviteMutation>;
export type CreateInviteMutationOptions = Apollo.BaseMutationOptions<CreateInviteMutation, CreateInviteMutationVariables>;
export const DeleteInviteDocument = gql`
    mutation deleteInvite($id: ID!) {
  deleteInvite(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteInviteMutationFn = Apollo.MutationFunction<DeleteInviteMutation, DeleteInviteMutationVariables>;

/**
 * __useDeleteInviteMutation__
 *
 * To run a mutation, you first call `useDeleteInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInviteMutation, { data, loading, error }] = useDeleteInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInviteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInviteMutation, DeleteInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInviteMutation, DeleteInviteMutationVariables>(DeleteInviteDocument, options);
      }
export type DeleteInviteMutationHookResult = ReturnType<typeof useDeleteInviteMutation>;
export type DeleteInviteMutationResult = Apollo.MutationResult<DeleteInviteMutation>;
export type DeleteInviteMutationOptions = Apollo.BaseMutationOptions<DeleteInviteMutation, DeleteInviteMutationVariables>;
export const ActivateDemoDocument = gql`
    mutation activateDemo($id: ID!) {
  activateDemo(id: $id) {
    done
  }
}
    `;
export type ActivateDemoMutationFn = Apollo.MutationFunction<ActivateDemoMutation, ActivateDemoMutationVariables>;

/**
 * __useActivateDemoMutation__
 *
 * To run a mutation, you first call `useActivateDemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateDemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateDemoMutation, { data, loading, error }] = useActivateDemoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateDemoMutation(baseOptions?: Apollo.MutationHookOptions<ActivateDemoMutation, ActivateDemoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateDemoMutation, ActivateDemoMutationVariables>(ActivateDemoDocument, options);
      }
export type ActivateDemoMutationHookResult = ReturnType<typeof useActivateDemoMutation>;
export type ActivateDemoMutationResult = Apollo.MutationResult<ActivateDemoMutation>;
export type ActivateDemoMutationOptions = Apollo.BaseMutationOptions<ActivateDemoMutation, ActivateDemoMutationVariables>;
export const UpdateDomainDocument = gql`
    mutation updateDomain($id: ID!, $data: DomainInput!) {
  updateDomain(id: $id, data: $data) {
    data {
      id
    }
  }
}
    `;
export type UpdateDomainMutationFn = Apollo.MutationFunction<UpdateDomainMutation, UpdateDomainMutationVariables>;

/**
 * __useUpdateDomainMutation__
 *
 * To run a mutation, you first call `useUpdateDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDomainMutation, { data, loading, error }] = useUpdateDomainMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDomainMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDomainMutation, UpdateDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDomainMutation, UpdateDomainMutationVariables>(UpdateDomainDocument, options);
      }
export type UpdateDomainMutationHookResult = ReturnType<typeof useUpdateDomainMutation>;
export type UpdateDomainMutationResult = Apollo.MutationResult<UpdateDomainMutation>;
export type UpdateDomainMutationOptions = Apollo.BaseMutationOptions<UpdateDomainMutation, UpdateDomainMutationVariables>;
export const InvitesDocument = gql`
    query invites($filters: InviteFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  invites(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        email
        status
      }
    }
  }
}
    `;

/**
 * __useInvitesQuery__
 *
 * To run a query within a React component, call `useInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useInvitesQuery(baseOptions?: Apollo.QueryHookOptions<InvitesQuery, InvitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvitesQuery, InvitesQueryVariables>(InvitesDocument, options);
      }
export function useInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvitesQuery, InvitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvitesQuery, InvitesQueryVariables>(InvitesDocument, options);
        }
export type InvitesQueryHookResult = ReturnType<typeof useInvitesQuery>;
export type InvitesLazyQueryHookResult = ReturnType<typeof useInvitesLazyQuery>;
export type InvitesQueryResult = Apollo.QueryResult<InvitesQuery, InvitesQueryVariables>;
export const DeleteUserLinkDomainDocument = gql`
    mutation deleteUserLinkDomain($id: ID!) {
  deleteUserLinkDomain(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteUserLinkDomainMutationFn = Apollo.MutationFunction<DeleteUserLinkDomainMutation, DeleteUserLinkDomainMutationVariables>;

/**
 * __useDeleteUserLinkDomainMutation__
 *
 * To run a mutation, you first call `useDeleteUserLinkDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserLinkDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserLinkDomainMutation, { data, loading, error }] = useDeleteUserLinkDomainMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserLinkDomainMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserLinkDomainMutation, DeleteUserLinkDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserLinkDomainMutation, DeleteUserLinkDomainMutationVariables>(DeleteUserLinkDomainDocument, options);
      }
export type DeleteUserLinkDomainMutationHookResult = ReturnType<typeof useDeleteUserLinkDomainMutation>;
export type DeleteUserLinkDomainMutationResult = Apollo.MutationResult<DeleteUserLinkDomainMutation>;
export type DeleteUserLinkDomainMutationOptions = Apollo.BaseMutationOptions<DeleteUserLinkDomainMutation, DeleteUserLinkDomainMutationVariables>;
export const UpdateUserLinkDomainDocument = gql`
    mutation updateUserLinkDomain($id: ID!, $data: UserLinkDomainInput!) {
  updateUserLinkDomain(id: $id, data: $data) {
    data {
      id
    }
  }
}
    `;
export type UpdateUserLinkDomainMutationFn = Apollo.MutationFunction<UpdateUserLinkDomainMutation, UpdateUserLinkDomainMutationVariables>;

/**
 * __useUpdateUserLinkDomainMutation__
 *
 * To run a mutation, you first call `useUpdateUserLinkDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLinkDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLinkDomainMutation, { data, loading, error }] = useUpdateUserLinkDomainMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserLinkDomainMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserLinkDomainMutation, UpdateUserLinkDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserLinkDomainMutation, UpdateUserLinkDomainMutationVariables>(UpdateUserLinkDomainDocument, options);
      }
export type UpdateUserLinkDomainMutationHookResult = ReturnType<typeof useUpdateUserLinkDomainMutation>;
export type UpdateUserLinkDomainMutationResult = Apollo.MutationResult<UpdateUserLinkDomainMutation>;
export type UpdateUserLinkDomainMutationOptions = Apollo.BaseMutationOptions<UpdateUserLinkDomainMutation, UpdateUserLinkDomainMutationVariables>;
export const EpicsDocument = gql`
    query epics($filters: EpicFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  epics(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        name
        color
      }
    }
  }
}
    `;

/**
 * __useEpicsQuery__
 *
 * To run a query within a React component, call `useEpicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEpicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEpicsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useEpicsQuery(baseOptions?: Apollo.QueryHookOptions<EpicsQuery, EpicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EpicsQuery, EpicsQueryVariables>(EpicsDocument, options);
      }
export function useEpicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EpicsQuery, EpicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EpicsQuery, EpicsQueryVariables>(EpicsDocument, options);
        }
export type EpicsQueryHookResult = ReturnType<typeof useEpicsQuery>;
export type EpicsLazyQueryHookResult = ReturnType<typeof useEpicsLazyQuery>;
export type EpicsQueryResult = Apollo.QueryResult<EpicsQuery, EpicsQueryVariables>;
export const CreateEpicDocument = gql`
    mutation createEpic($data: EpicInput!) {
  createEpic(data: $data) {
    data {
      id
    }
  }
}
    `;
export type CreateEpicMutationFn = Apollo.MutationFunction<CreateEpicMutation, CreateEpicMutationVariables>;

/**
 * __useCreateEpicMutation__
 *
 * To run a mutation, you first call `useCreateEpicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEpicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEpicMutation, { data, loading, error }] = useCreateEpicMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEpicMutation(baseOptions?: Apollo.MutationHookOptions<CreateEpicMutation, CreateEpicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEpicMutation, CreateEpicMutationVariables>(CreateEpicDocument, options);
      }
export type CreateEpicMutationHookResult = ReturnType<typeof useCreateEpicMutation>;
export type CreateEpicMutationResult = Apollo.MutationResult<CreateEpicMutation>;
export type CreateEpicMutationOptions = Apollo.BaseMutationOptions<CreateEpicMutation, CreateEpicMutationVariables>;
export const UpdateEpicDocument = gql`
    mutation updateEpic($id: ID!, $data: EpicInput!) {
  updateEpic(id: $id, data: $data) {
    data {
      id
    }
  }
}
    `;
export type UpdateEpicMutationFn = Apollo.MutationFunction<UpdateEpicMutation, UpdateEpicMutationVariables>;

/**
 * __useUpdateEpicMutation__
 *
 * To run a mutation, you first call `useUpdateEpicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEpicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEpicMutation, { data, loading, error }] = useUpdateEpicMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEpicMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEpicMutation, UpdateEpicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEpicMutation, UpdateEpicMutationVariables>(UpdateEpicDocument, options);
      }
export type UpdateEpicMutationHookResult = ReturnType<typeof useUpdateEpicMutation>;
export type UpdateEpicMutationResult = Apollo.MutationResult<UpdateEpicMutation>;
export type UpdateEpicMutationOptions = Apollo.BaseMutationOptions<UpdateEpicMutation, UpdateEpicMutationVariables>;
export const DeleteEpicDocument = gql`
    mutation deleteEpic($id: ID!) {
  deleteEpic(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteEpicMutationFn = Apollo.MutationFunction<DeleteEpicMutation, DeleteEpicMutationVariables>;

/**
 * __useDeleteEpicMutation__
 *
 * To run a mutation, you first call `useDeleteEpicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEpicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEpicMutation, { data, loading, error }] = useDeleteEpicMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEpicMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEpicMutation, DeleteEpicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEpicMutation, DeleteEpicMutationVariables>(DeleteEpicDocument, options);
      }
export type DeleteEpicMutationHookResult = ReturnType<typeof useDeleteEpicMutation>;
export type DeleteEpicMutationResult = Apollo.MutationResult<DeleteEpicMutation>;
export type DeleteEpicMutationOptions = Apollo.BaseMutationOptions<DeleteEpicMutation, DeleteEpicMutationVariables>;
export const TasksDocument = gql`
    query tasks($filters: TaskFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  tasks(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        name
        status
        reportsMinutes
        timersSeconds
        users {
          data {
            id
            attributes {
              username
              color
            }
          }
        }
        company {
          data {
            id
            attributes {
              name
              color
            }
          }
        }
        epic {
          data {
            id
            attributes {
              name
              color
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useTasksQuery__
 *
 * To run a query within a React component, call `useTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useTasksQuery(baseOptions?: Apollo.QueryHookOptions<TasksQuery, TasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
      }
export function useTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksQuery, TasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
        }
export type TasksQueryHookResult = ReturnType<typeof useTasksQuery>;
export type TasksLazyQueryHookResult = ReturnType<typeof useTasksLazyQuery>;
export type TasksQueryResult = Apollo.QueryResult<TasksQuery, TasksQueryVariables>;
export const CreateTaskDocument = gql`
    mutation createTask($data: TaskInput!) {
  createTask(data: $data) {
    data {
      id
    }
  }
}
    `;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, options);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<CreateTaskMutation, CreateTaskMutationVariables>;
export const UpdateTaskDocument = gql`
    mutation updateTask($id: ID!, $data: TaskInput!) {
  updateTask(id: $id, data: $data) {
    data {
      id
    }
  }
}
    `;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const InviteDocument = gql`
    query invite($id: ID!) {
  invite(id: $id) {
    data {
      id
      attributes {
        email
        status
        domain {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useInviteQuery__
 *
 * To run a query within a React component, call `useInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInviteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInviteQuery(baseOptions: Apollo.QueryHookOptions<InviteQuery, InviteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InviteQuery, InviteQueryVariables>(InviteDocument, options);
      }
export function useInviteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InviteQuery, InviteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InviteQuery, InviteQueryVariables>(InviteDocument, options);
        }
export type InviteQueryHookResult = ReturnType<typeof useInviteQuery>;
export type InviteLazyQueryHookResult = ReturnType<typeof useInviteLazyQuery>;
export type InviteQueryResult = Apollo.QueryResult<InviteQuery, InviteQueryVariables>;
export const AcceptInviteDocument = gql`
    mutation acceptInvite($id: ID!) {
  acceptInvite(id: $id) {
    done
  }
}
    `;
export type AcceptInviteMutationFn = Apollo.MutationFunction<AcceptInviteMutation, AcceptInviteMutationVariables>;

/**
 * __useAcceptInviteMutation__
 *
 * To run a mutation, you first call `useAcceptInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInviteMutation, { data, loading, error }] = useAcceptInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcceptInviteMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInviteMutation, AcceptInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptInviteMutation, AcceptInviteMutationVariables>(AcceptInviteDocument, options);
      }
export type AcceptInviteMutationHookResult = ReturnType<typeof useAcceptInviteMutation>;
export type AcceptInviteMutationResult = Apollo.MutationResult<AcceptInviteMutation>;
export type AcceptInviteMutationOptions = Apollo.BaseMutationOptions<AcceptInviteMutation, AcceptInviteMutationVariables>;
export const TaskDocument = gql`
    query task($id: ID) {
  task(id: $id) {
    data {
      id
      attributes {
        name
        status
        body
        content
        users {
          data {
            id
            attributes {
              username
              color
            }
          }
        }
        company {
          data {
            id
            attributes {
              name
              color
            }
          }
        }
        epic {
          data {
            id
            attributes {
              name
              color
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useTaskQuery__
 *
 * To run a query within a React component, call `useTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskQuery(baseOptions?: Apollo.QueryHookOptions<TaskQuery, TaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
      }
export function useTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskQuery, TaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
        }
export type TaskQueryHookResult = ReturnType<typeof useTaskQuery>;
export type TaskLazyQueryHookResult = ReturnType<typeof useTaskLazyQuery>;
export type TaskQueryResult = Apollo.QueryResult<TaskQuery, TaskQueryVariables>;