import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { observer } from 'mobx-react-lite';
import { Store } from 'model/store/Store';

import { Page } from '../../../components/Page/Page';
import { useCreateInviteMutation } from '../../../generated/graphql';

interface IForm {
  email: string
}

export const NewInvite = observer(() => {

  const { domain } = useInstance(Store)
  const {t} = useTranslation()

  const [createInvite, {loading}] = useCreateInviteMutation()

  const navigate = useNavigate()

  const onFinish = async ({email} : IForm) => {
    await createInvite({
      variables: {
        data: {
          email: email,
          domain: domain.selectedDomain || ''
        }      },
      onCompleted: () => {
        navigate(-1)
      }
    })
  }

  return (
    <Page name={t('Invite to Workspace')!} title={domain.currentDomain().name}>
      <Page.Content>

        <Form name={'invite'}
              layout={'vertical'}
              size={'large'}
              onFinish={onFinish}
              scrollToFirstError
              disabled={loading}
        >

          <Form.Item name='email' label={t('Email')}
                     rules={[
                       {required: true, message: t('Required')!},
                       {type: 'email', message: t('Invalid format')!},
                     ]}

          >
            <Input/>
          </Form.Item>

          <Form.Item>
            <Button block type={'primary'} htmlType={'submit'} loading={loading}>{t('Invite')}</Button>
          </Form.Item>

        </Form>

      </Page.Content>
    </Page>
  )

})