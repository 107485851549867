import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { message } from 'antd';
import { createUploadLink } from 'apollo-upload-client';
import { getApiBase } from 'env';

import { IRootStoreModel } from '../store/RootStore';

const API_BASE = getApiBase();

const ApiClientFactory = (store: IRootStoreModel) => {
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const { response } = operation.getContext();

    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
          message.error(error.message).then()
          console.error(`[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`,)
        },
      );
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);

      if (response && response.status === 401) {
        // Если токен сдох, то разлогиниваем пользователя
        store.auth.clearState();
      }

    }
  });

  const authLink = setContext((_, { headers }) => {
    if (!store.auth.token) {
      return headers;
    }
    const token = store.auth.token;

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const uploadLink = createUploadLink({
    uri: `${API_BASE}/graphql`,
  });

  return new ApolloClient({
    link: ApolloLink.from([ errorLink, authLink, uploadLink ]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    }
  });
};

export default ApiClientFactory;
