import { useState } from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'
import _ from 'lodash';

import { SecondsFormat, TimeFormat } from '../../shared/TimeFormat';

import { BoardData } from './Board';
import { Tag } from './Tag';

import cls from './Card.module.scss';
dayjs.extend(duration)

interface ICard {
  data: BoardData;
  onCardOpen?: () => void;
  onCardDrag?: () => void;
  onCardDrop?: () => void;
  onCardPreview?: () => void;
}

export const Card = ({ data, onCardOpen, onCardDrag }: ICard) => {

  const onMouseDown = () => {
    console.log('pressed');
  };

  const [isDragging, setIsDragging] = useState<boolean>(false);

  function handleDragStart(e: any) {
    setIsDragging(true);
    const data = JSON.stringify({ type: 'card' });
    e.dataTransfer.setData('text/plain', data);
    // if (onCardDrag) {onCardDrag()}
    // console.log('start', e);
  }

  function handleDragEnd(e: any) {
    setIsDragging(false);
    e.dataTransfer.clearData();
    // if (onCardDrop) {onCardDrop()}
    // console.log('end', e);
  }

  return (
    <div className={cn([cls.card, isDragging && cls.dragging])}
         onClick={onCardOpen}
         onDrag={onCardDrag}
         onDragStart={handleDragStart}
         onMouseDown={onMouseDown}
         onDragEnd={handleDragEnd}
         draggable
    >
      <div className={cls.title}>{data.name}</div>
      <div className={cls.tags}>
        {data.client && <Tag data={data.client} />}
        {data.epic && <Tag data={data.epic} />}
        {_.map(data.users, (user, index) => (<Tag key={index} data={user} />))}
        {data.reportsMinutes > 0 && <Tag data={{ id: 'report', name: `✅ ${TimeFormat(data.reportsMinutes)}`, color: '#333333' }} />}
        {data.timersSeconds > 0 && <Tag data={{ id: 'timer', name: `🕑 ${SecondsFormat(data.timersSeconds)}`, color: '#333333' }} />}
      </div>
    </div>
  );
}