import {
  applySnapshot,
  types as t,
} from 'mobx-state-tree';

const initialAuthStore = {
  token: null,
  refresh: null,
  id: null,
  role: null,
  data: null,
};

export const AuthStore = t
  .model({
    token: t.maybeNull(t.string),
    refresh: t.maybeNull(t.boolean),
    id: t.maybeNull(t.string),
    role: t.maybeNull(t.string),
    data: t.maybeNull(t.model({
      username: t.maybeNull(t.string),
      email: t.maybeNull(t.string),
      color: t.maybeNull(t.string),
      focusedAt: t.maybeNull(t.string),
    })),
  })
  .views((self => ({
    get hasAuthToken() {
      return !!self.token;
    },
  })))
  .actions((self) => ({
    clearState: () => {
      applySnapshot(self, initialAuthStore);
    },
    setToken: (token: string | null) => {
      self.token = token;
    },
    setTokenAndId(token: string | null, id: string | null) {
      self.token = token;
      self.id = id;
    },
    setRoleAndData(role: string | null, data: { email: string | null, username: string | null , color: string | null, focusedAt: string | null} | null) {
      self.role = role;
      self.data = data;
    },
    setRefresh (value: boolean) {
      self.refresh = value
    },
    needUpdate() {
      self.refresh = true;
    },
  }));
