import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { EditOutlined,
  MoreOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Avatar, Button, ColorPicker, Dropdown, Form, Input, Modal, Skeleton, Switch } from 'antd';
import {
  useCompaniesQuery, useCreateCompanyMutation, useUpdateCompanyMutation
} from 'generated/graphql';
import _, { isString } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Store } from 'model/store/Store';

import { Page } from '../../../components/Page/Page';
import { presets, random } from '../../../config/colors';

import cls from './Clients.module.scss';

export const Clients = observer(() => {

  const { domain } = useInstance(Store);
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [form] = Form.useForm()

  const companies = useCompaniesQuery({
    variables: {
      filters: {
        domain: {id: {eq: domain.selectedDomain}},
      },
      sort: ['enabled:DESC, name:ASC'],
      pagination: { limit: 100 },
    }
  });

  const [updateCompany] = useUpdateCompanyMutation()
  const [createCompany] = useCreateCompanyMutation()

  const onClose = () => {
    setOpen(false)
    // form.resetFields()
  }

  const onFinish = async () => {
    const data = {
      id: form.getFieldValue('id'),
      name: form.getFieldValue('name'),
      color: isString(form.getFieldValue('color')) ? form.getFieldValue('color') : form.getFieldValue('color').toHexString() ,
    }
    if (data.id) {
      await updateCompany ({
        variables: {
          id: data.id,
          data: {
            name: data.name,
            color: data.color,
          }
        },
        onCompleted: () => {
          companies.refetch()
          setOpen(false)
        }
      })
    } else {
      await createCompany ({
        variables: {
          data: {
            name: data.name,
            color: data.color,
            domain: domain.selectedDomain,
          }
        },
        onCompleted: () => {
          companies.refetch()
          setOpen(false)
        }
      })

    }
  }

  const onToggleEnabled = async (id: string, newValue: boolean) => {
    await updateCompany({
      variables: {
        id: id,
        data: {enabled: newValue}
      },
      onCompleted: () => {
        companies.refetch()
      }
    })
  }

  return (
    <Page name={t('Clients')!}>
      <Page.Content>

        {companies.loading && <Skeleton active />}

      </Page.Content>

      {_.map(companies.data?.companies?.data, (client, index) => (
        <React.Fragment key={client.id}>
          <Page.Content>
            <div className={cls.row}>

              <div className={cls.number}>{index + 1}</div>

              <div className={cls.name}>
                <Avatar size={'small'} style={{ backgroundColor: client.attributes?.color! }}/> {client.attributes?.name}
              </div>

              <div className={cls.control}>
                <Switch checked={client.attributes?.enabled}
                        onChange={() => onToggleEnabled(client.id!, !client.attributes?.enabled)}
                />
                <Dropdown menu={{
                  items: [
                    {
                      key: 'edit',
                      label: t('Edit'),
                      icon: <EditOutlined/>,
                      onClick: () => {
                        form.setFieldValue('id', client.id)
                        form.setFieldValue('name', client.attributes?.name)
                        form.setFieldValue('color', client.attributes?.color)
                        setOpen(true)
                      },
                    },
                    ]
                }} trigger={['click']}>
                  <Button type={'text'} icon={<MoreOutlined />} />
                </Dropdown>
              </div>

            </div>
          </Page.Content>
          <Page.Divider />
        </React.Fragment>
      ))}

      <Page.Content>
        <Button shape={'circle'} icon={<PlusOutlined />} className={cls.plus} onClick={() => {
            // form.setFieldValue('id', 'new')
            // form.setFieldValue('name', '')
            // form.setFieldValue('color', '')
            form.resetFields()
            setOpen(true)
        }} />
      </Page.Content>
      <Page.Divider />
      
      <Modal open={open} onCancel={onClose} footer={null} closable={false} width={360}>
        <Form layout={'inline'} onFinish={onFinish} form={form}>
          <Form.Item name={'name'}
                     rules={[
                       {
                         required: true,
                         message: t('Please enter the data') || '',
                       },
                     ]}
                     initialValue={''}
          >
            <Input bordered={false} placeholder={t('New Client')!} style={{width: 148}}/>
          </Form.Item>
          <Form.Item name={'color'} initialValue={random()}
                     rules={[
                       {
                         required: true,
                         message: t('Please enter the data') || '',
                       },
                     ]}
          >
            <ColorPicker presets={presets} panelRender={(_, {components: {Presets}}) => <Presets />}/>
          </Form.Item>
          <Form.Item>
            {!form.getFieldValue('id') ?
              <Button htmlType={'submit'} icon={<PlusOutlined />}>{t('Add')}</Button>
              :
              <Button htmlType={'submit'}>{t('Save')}</Button>
            }
          </Form.Item>
        </Form>

      </Modal>

    </Page>
  );

});
