import React, { FC, useCallback } from 'react';
import { useInstance } from 'react-ioc';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  useTasksQuery,
  useUpdateTaskMutation
} from 'generated/graphql';
import { LayoutIndex } from 'layouts/LayoutIndex';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { Store } from 'model/store/Store';
import { IStatus, status } from 'shared/status';

import { Board, BoardData } from 'components/Board/Board';
import { Filters } from 'components/Filter/Filters';

import { goToTask } from '../../routes';

import { filtersToQuery, filtersToSearchParams, searchParamsToFilters } from './filter';

export const Archive: FC = observer(() => {

  const store = useInstance(Store);
  const navigate = useNavigate();


  // работа с фильтрами
  const [searchParams, setSearchParams] = useSearchParams();
  const filters = searchParamsToFilters(searchParams);

  const onEpicChanges = useCallback((data: string[] | null) => {
    setSearchParams(filtersToSearchParams({
      ...filters,
      epics: data,
    }))
  }, [filters, setSearchParams])
  const onClientChanges = (data: string[] | null) => {
    setSearchParams(filtersToSearchParams({
      ...filters,
      clients: data,
    }))
  }
  const onTeamChanges = (data: string[] | null) => {
    setSearchParams(filtersToSearchParams({
      ...filters,
      team: data,
    }))
  }

  const visibleStatus:IStatus[] = _.filter(status, item => !item.visible) as IStatus[]
  const visibleStatusIds = _.map(visibleStatus, item => item.name)
  // console.log('visibleStatus', visibleStatus);
  // console.log('visibleStatusIds', visibleStatusIds);

  const query = useTasksQuery({
    variables: {
      filters: {
        domain: {id: {eq: store.domain.selectedDomain}},
        enabled: {eq: true},
        status: {in: visibleStatusIds},
        ...filtersToQuery(filters),
      },
      pagination: {
        limit: 1000,
      }
    },
    pollInterval: 2000,
  })
  const [updateTask] = useUpdateTaskMutation()

  const data:BoardData[] = _.map(query.data?.tasks?.data, item => (
    {
      id: item.id!,
      name: item.attributes?.name!,
      status: item.attributes?.status!,
      timersSeconds: item.attributes?.timersSeconds!,
      reportsMinutes: item.attributes?.reportsMinutes!,
      ...(item.attributes?.company?.data && {
          client: {
            id: item.attributes.company.data.id!,
            name: item.attributes?.company?.data?.attributes?.name!,
            color: item.attributes?.company?.data?.attributes?.color!,
          },
        }),
      ...(item.attributes?.epic?.data && {
        epic: {
          id: item.attributes.epic.data.id!,
          name: item.attributes?.epic?.data?.attributes?.name!,
          color: item.attributes?.epic?.data?.attributes?.color!,
        },
      }),
      users: _.map(item.attributes?.users?.data, user => ({
        id: user.id!,
        name: user.attributes?.username!,
        color: user.attributes?.color!,
      })),
    }
    ))

  const onCreate = (status:string) => {
    navigate(goToTask('new'), { state: { status: status } })

  }

  const onOpen = (id: string) => {
    navigate(goToTask(id))
  }
  
  const onDrag = async (id:string, status:string) => {
    await updateTask({
      variables: {
        id: id,
        data: {
          status: status
        }
      },
      onCompleted: () => {
        query.refetch()
      }
    })
  }

  ///* eslint-disable-next-line i18next/no-literal-string */
  return (
    <LayoutIndex filters={<Filters selectedClients={filters.clients}
                                   selectedEpics={filters.epics}
                                   selectedTeam={filters.team}
                                   onChangeEpics={onEpicChanges}
                                   onChangeClients={onClientChanges}
                                   onChangeTeam={onTeamChanges}
    />}>
      <Board
        status={visibleStatus}
        data={data}
        onCreate={onCreate}
        onOpen={onOpen}
        onDrag={onDrag}
        loading={query.loading}
      />
    </LayoutIndex>
  )
});