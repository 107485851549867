
export function TimeFormat (minutes:number) {

  const H = Math.floor(minutes / 60)
  const M = minutes % 60

  const HH = H < 10 ? `0${H}` : H
  const MM = M < 10 ? `0${M}` : M

  return `${HH}:${MM}`
}

export function SecondsFormat (seconds:number) {

  const H = Math.floor(seconds / 3600)
  const M = Math.floor((seconds % 3600) / 60)

  const HH = H < 10 ? `0${H}` : H
  const MM = M < 10 ? `0${M}` : M

  return `${HH}:${MM}`
}

export function FullSecondsFormat (seconds:number) {

  const H = Math.floor(seconds / 3600)
  const M = Math.floor((seconds % 3600) / 60)
  const S =(seconds % 3600) % 60

  const HH = H < 10 ? `0${H}` : H
  const MM = M < 10 ? `0${M}` : M
  const SS = S < 10 ? `0${S}` : S

  return `${HH}:${MM}:${SS}`
}